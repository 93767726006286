<template lang="pug">
div.index-list-books
  ErrorAlert(
    v-if="error"
    :reporting="{ message: error.message, extra: error }"
    message="Failed to load the scripture index."
    @retry="query.refetch"
  )

  VSkeletonLoader(
    v-else-if="query.isPending.value"
    type="article, actions"
    max-height="180px"
    width="200px"
  )

  VRow.index-list-books__row(
    v-else
    v-drag-scroll
    :class="{ 'index-list-books__row--side-scroll': sideScroll }"
  )
    VCol(
      v-for="book in books"
      :key="book.range.bid"
    )
      IndexCardBook(
        :index="book"
        :by-chapter-action="byChapterAction"
      )
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useQuery } from "@tanstack/vue-query";
import type { StandaloneBookData } from "@rsc/scripture-model";
import {
  type CommentaryIndexRangeCount,
  getCommentaryIndexByRanges,
} from "~/units/commentary/commentaryIndex";
import { injectRequired } from "~/injectRequired";
import { LoggerKey, ScriptureClientKey, SolrClientKey } from "~/injectionKeys";
import {
  logSsrErrorToSentry,
  makeResultAsyncSerializable,
} from "~/errors/util";

const solrClient = injectRequired(SolrClientKey);
const scriptureClient = injectRequired(ScriptureClientKey);
const i18n = useI18n();
const locale = i18n.locale;
const logger = injectRequired(LoggerKey);

interface Props {
  /**
   * The ID of the user's preferred local translation.
   */
  localTid: string;

  /**
   * If true, show everything on one line, and allow horizontal scrolling by swiping.
   */
  sideScroll?: boolean;

  /**
   * A function to call when clicking a `By Chapter` button.
   * If not used, the button will be a link.
   */
  byChapterAction?: (bid: string) => void;
}

const props = withDefaults(defineProps<Props>(), {});

const query = useQuery({
  queryKey: ["IndexListBooks", computed(() => props.localTid), locale],
  queryFn: () =>
    makeResultAsyncSerializable(
      () =>
        scriptureClient
          .getBooks(props.localTid)
          .andThen((ranges) =>
            getCommentaryIndexByRanges(ranges, solrClient, locale.value),
          ),
      (e, s) => logSsrErrorToSentry(logger, e, s),
    ),
});

const error = computed(
  () => query.error.value ?? query.data.value?.error ?? null,
);

const books = computed<CommentaryIndexRangeCount<StandaloneBookData>[]>(
  () => query.data.value?.value?.ranges ?? [],
);

onServerPrefetch(async () => {
  await query.suspense();
});
</script>

<style lang="scss" scoped>
.index-list-books__row--side-scroll {
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-top: 12px;
  padding-bottom: 12px;
  cursor: grab;

  &.active {
    cursor: grabbing;
  }
}
</style>
